<template>
  <div class="signin">
    <van-nav-bar title="签到" fixed left-arrow @click-left="onBack">
      <template #right @click-right="userKefu">
        <van-icon name="service-o" />
      </template>
    </van-nav-bar>
    <div class="content1">
      <van-cell center class="content-top">
        <template #title>
          <span class="custom-title">我的积分：<b>50</b></span>
        </template>
        <template #icon>
          <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-jichu.png" />
        </template>
        <template #right-icon>
          <span style="margin-right:0.5rem">签到提醒</span>
          <van-switch v-model="checked" size="2.2rem" active-color="#ee0a24" inactive-color="#dcdee0" />
        </template>
      </van-cell>
      <van-grid :column-num="7" class="qiandao">
        <van-grid-item v-for="(item, index) in gridtextlist1" :key="index" :text="item.text">
          <template #text>
            <span class="custom-title" :style="{'color':item.color1}">{{ item.text }}</span>
          </template>
          <template #icon>
            <span class="jifen" :style="{'background':item.bgcolor,'color':item.color}">{{ item.num }}</span>
          </template>
        </van-grid-item>
        {{ item }}
      </van-grid>
      <van-cell center class="bottom">
        <template #title>
          <span class="custom-title">已连续签到<b>50</b>天</span>
        </template>
        <template #right-icon>
          <van-tag round plain color="#cccccc" text-color="#656466">
            <van-icon name="question-o" />签到规则
          </van-tag>
        </template>
      </van-cell>

      <van-grid :column-num="3" class="youhui">
        <van-grid-item v-for="(item, index) in gridtextlist2" :key="index" :text="item.text" :icon="item.photo" >
          <template #text>
            <span class="custom-title">{{item.text}}</span>
          </template>
        </van-grid-item>
        {{ item }}
      </van-grid>
      <div class="zuorenwu">
        <div class="title">做任务，赚积分</div>
        <van-cell center class="renwu-cell">
          <template #title>
            <span class="custom-title">每日签到<b>+2</b></span>
          </template>
          <template #label>
            <span>用户每天签到都能获得积分</span>
          </template>
          <template #icon>
            <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-jichu.png" />
          </template>
          <template #right-icon>
            <van-tag round size="medium" color="#f3f3f3" s text-color="#656466">去完成</van-tag>
          </template>
        </van-cell>
        <van-cell center class="renwu-cell">
          <template #title>
            <span class="custom-title">邀请好友<b>+10</b></span>
          </template>
          <template #label>
            <span>邀请好友注册获得积分</span>
          </template>
          <template #icon>
            <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-jichu.png" />
          </template>
          <template #right-icon>
            <van-tag round size="medium" color="#f3f3f3" s text-color="#656466">去完成</van-tag>
          </template>
        </van-cell>
        <van-cell center class="renwu-cell">
          <template #title>
            <span class="custom-title">商品买卖<b>+2</b></span>
          </template>
          <template #label>
            <span>订单服务完成后每消费2元获得1积分</span>
          </template>
          <template #icon>
            <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-jichu.png" />
          </template>
          <template #right-icon>
            <van-tag round size="renwu-cell" color="#f3f3f3" s text-color="#656466">去完成</van-tag>
          </template>
        </van-cell>
        <van-cell center class="renwu-cell">
          <template #title>
            <span class="custom-title">视频学习<b>+2</b></span>
          </template>
          <template #label>
            <span>学习打卡每学习一小时获得2积分</span>
          </template>
          <template #icon>
            <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-jichu.png" />
          </template>
          <template #right-icon>
            <van-tag round size="medium" color="#f3f3f3" s text-color="#656466">去完成</van-tag>
          </template>
        </van-cell>
        <van-cell center class="renwu-cell">
          <template #title>
            <span class="custom-title">每日一练<b>+2</b></span>
          </template>
          <template #label>
            <span>用户每天完成每日一练获得2积分</span>
          </template>
          <template #icon>
            <van-icon name="https://cdnoss.ksyt.com.cn/wxImages/icon-jichu.png" />
          </template>
          <template #right-icon>
            <van-tag round size="medium" color="#f3f3f3" s text-color="#656466">去完成</van-tag>
          </template>
        </van-cell>
      </div>

    </div>
    <!-- <van-row class="top">
      <van-col span="12" class="top_left">
        <van-button color="#ffffff">执业药师<van-icon name="play" /></van-button>
      </van-col>
      <van-col span="12" class="top_right">
        <van-grid :column-num="3">
          <van-grid-item icon="search" text="搜索"  @click="handleClickSearch" />
          <van-grid-item icon="smile-comment-o" text="签到" />
          <van-grid-item icon="comment-o" text="客服" />
        </van-grid>
      </van-col>
    </van-row> -->

  </div>
</template>

<script>

export default {
  data() {

    return {
      item:'',
      checked: false,
      gridtextlist1: [
        {
          text: "周一",
          icon: "n",
          num: "+10",
          color: "#666666",
          color1: "#666666",
          bgcolor: "#f5f5f5"
        },
        {
          text: "周二",
          icon: "w",
          num: "+20",
          color: "#ffffff",
          color1: "#1989fa",
          bgcolor: "#1989fa"
        },
        {
          text: "周三",
          icon: "j",
          num: "+30",
          color: "#666666",
          color1: "#666666",
          bgcolor: "#f5f5f5"
        },
        {
          text: "周四",
          icon: "g",
          num: "+30",
          color: "#666666",
          color1: "#666666",
          bgcolor: "#f5f5f5"
        },
        {
          text: "周五",
          icon: "h",
          num: "+30",
          color: "#666666",
          color1: "#666666",
          bgcolor: "#f5f5f5"
        },
        {
          text: "周六",
          icon: "h",
          num: "+30",
          color: "#666666",
          color1: "#666666",
          bgcolor: "#f5f5f5"
        },
        {
          text: "周日",
          icon: "h",
          num: "+40",
          color: "#666666",
          color1: "#666666",
          bgcolor: "#f5f5f5"
        },
      ],
      
      gridtextlist2:[
            {
                text:"积分兑换",
                icon:"n",
                photo:"https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_shiting.png"
            },
            {
                text: "积分抽奖",
                icon:"w",
                photo:"https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_zhibo.png"
            },
            {
                text:"红包卡券",
                icon:"j",
                photo:"https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_kecheng.png"
            }  
      ],

    };

  },
  methods: {
			onBack() {
				history.back();
			},
			userKefu(){
				this.$router.push({ path: '/user' });
			}	
	}

};

</script>
<style lang="less">
@import '~@/assets/styles/common.less';

.signin {
  // background-color: #ffffff;
  // 
  text-align: center;
  color: #666666;
  /* background-image: url('https://cdnoss.ksyt.com.cn/wxImages/2022yaoshi_yuekao.jpg'); */
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #f5f5f5;
}

.signin .van-nav-bar {
  background: #1989fa;
  z-index: 999999;
}

.signin .van-hairline--bottom::after {
  border-bottom-width: 0;
}

.signin .van-nav-bar .van-icon {
  color: #ffffff;
  font-size: 3.2rem !important;
}

.signin .van-nav-bar__title {
  color: #ffffff;
}

.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }

.content1 {
  padding: 15rem 1.3rem 1.3rem 1.3rem;
}

.content-top {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: 8rem;
  padding: 1rem;
}

.content-top .van-icon__image {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 100%;
}

.content-top .van-cell__title {
  margin-left: 0.8rem;
}

.content-top .van-cell__title .custom-title {
  // font-size: 2.4rem;
}

.content-top .van-cell__title .custom-title b {
  font-weight: 600;
}

.bottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  height: 6rem;
  padding: 1rem;
}

.bottom .van-icon__image {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 100%;
}

.bottom .van-cell__title {
  margin-left: 0.8rem;
}

.bottom .van-cell__title .custom-title {
  // font-size: 2.4rem;
}

.bottom .van-cell__title .custom-title b {
  font-weight: 600;
  font-size: 2.3rem;
  padding: 0 0.8rem;
  color: #fb4d19;
}

.qiandao {
  height: 12rem;
  padding: 0 0.6rem;
  background-color: #ffffff;
}

.qiandao .van-grid-item__content::after {
  border-width: 0;
}

.jifen {
  font-size: 1.6rem;
  color: #666666;
  background-color: #f5f5f5;
  height: 4rem;
  width: 4rem;
  text-align: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qiandao .van-grid-item .van-grid-item__content {
  height: 8rem;
  margin: 2rem 0.4rem;
  border-radius: 1rem;
}

.qiandao .van-grid-item .custom-title {
  color: #666666;
  font-size: 1.6rem;
  line-height: 2rem;
  height: 2rem;
  padding-top: 0.8rem;
}

.guize {
  margin: 0 auto;
  margin-bottom: 2.4rem;
}

.van-hairline--top::after {
  border-top-width: 0;
}

.youhui {
  height: 15rem;
  margin-top: 2rem;
  padding: 0 0.6rem;
  border-radius: 0.9rem;
  background-color: #ffffff;
}
.youhui .van-grid-item__content::after {
  border-width: 0;
}

.youhui .van-icon .van-icon__image {
  height: 6rem;
  width: 6rem;
  padding-bottom: 1rem;
}

.youhui .custom-title {
  font-size: 1.8rem;
}

.zuorenwu {
  margin-top: 2rem;
  padding-bottom: 1rem;
  height: auto;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0px 3px 4px rgb(223 223 223 / 60%);
}

.zuorenwu .title {
  height: 6rem;
  padding: 0.5rem;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.renwu-cell {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  height: auto;
  padding: 1.8rem 1rem;
}

.renwu-cell .van-icon__image {
  width: 5.4rem;
  height: 5.4rem;
}

.renwu-cell .van-cell__title {
  margin-left: 0.8rem;
}

.renwu-cell .van-cell__title .custom-title {
  // font-size: 2.4rem;
}

.renwu-cell .van-cell__title .custom-title b {
  font-weight: 600;
  font-size: 2.3rem;
  padding: 0 0.8rem;
  color: #fb4d19;
}
</style>
